import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Route, Router, Switch } from 'react-router-dom';
import history from './history';
import routes from './routes';
// Analytics
import * as Analytics from './common/analytics';
// Components
import WhatsappFloatButton from './WhatsappFloatButton';
import {
  Alert,
  NotFoundPage,
  ScrollToTop,
  TimeOnPageProfiler,
  TopLevelErrorBoundary,
} from './common';
import { Footer } from './footer';
import { Header, NavHeader, SimpleHeader } from './header';
import { Preferences } from './preferences';
import { UserValidate, Wishlist } from './user';
// Helpers
import _ from 'lodash';
// Styles
import 'bootstrap/dist/css/bootstrap.css';
import 'instantsearch.css/themes/reset.css';
import './App.css';
// Sentry
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { AdvertisingPopup } from './advertising';

// Assets
import logo from './snowflake.png';
import Snowfall from 'react-snowfall';

// Temporary Settings to SnowFall
const snowflake = document.createElement('img');
snowflake.src = logo;
const images = [snowflake];
const radius = [10, 15];
const speed = [2.0, 3.0];

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.NODE_ENV,
  release: process.env.REACT_APP_BUILD_COMMIT,
  autoSessionTracking: false,
  integrations: [new BrowserTracing()],
  normalizeDepth: 10,
});

const App = ({ user }) => {
  useEffect(() => {
    Analytics.setUserProperties(user);
  }, [user]);

  return (
    <TopLevelErrorBoundary>
      <div>
        <Snowfall
          color="#BACBD9"
          snowflakeCount={80}
          images={images}
          radius={radius}
          speed={speed}
        />
        <Router history={history}>
          <ScrollToTop>
            <div id="app">
              <Wishlist />
              <Preferences />
              <UserValidate />
              <Alert />
              <WhatsappFloatButton />
              <AdvertisingPopup />
              <TimeOnPageProfiler />
              <header>
                <Switch>
                  <Route path="/checkout" component={SimpleHeader} />
                  <Route path="/login" component={SimpleHeader} />
                  <Route path="/cadastro" component={SimpleHeader} />
                  <Route path="/registro" component={SimpleHeader} />
                  <Route path="/esqueci-senha" component={Header} />
                  <Route path="/resetar-senha" component={Header} />
                  <Route path="/confirmacao" component={SimpleHeader} />
                  <Route path="/pix-payment" component={SimpleHeader} />
                  <Route path="/blog" component={NavHeader} />
                  <Route path="/lp" component={Header} />
                  <Route component={Header} />
                </Switch>
              </header>
              <main>
                <Switch>
                  {routes.map((route, i) => {
                    const { component, ...options } = route;
                    return <Route key={i} component={component} {...options} />;
                  })}
                  <Route component={NotFoundPage} />
                </Switch>
              </main>
              <footer>
                <Switch>
                  <Route component={Footer} />
                </Switch>
              </footer>
            </div>
          </ScrollToTop>
        </Router>
      </div>
    </TopLevelErrorBoundary>
  );
};

const mapStateToProps = state => {
  return {
    user: {
      id: state.user.information.id || null,
      name: `${state.user.information.firstName} ${state.user.information.lastName}`,
      email: state.user.information.email || '',
      isBusiness: !!_.find(state.user.addresses, a => a.cnpj && a.cnpj !== ''),
      createdAt: state.user.information.createdAt || '',
    },
  };
};

const connector: Connector<{}, Props> = connect(mapStateToProps);

export default connector(App);
